<template>
  <loginForm type="forgetPassword" />
</template>

<script>
import loginForm from '@/components/loginForm'
import webviewBack from '@/mixins/webviewBack'

export default {
  name: 'ForgetPassword',
  mixins: [webviewBack],
  components: {
    loginForm
  }
}
</script>
